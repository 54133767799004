.hero__name--blog {
  font-family: 'DS Kork';
  font-size: 63px;
  font-weight: 420;
  @include phones-small {
    font-size: 35px;
  }
}


.blog {
  display: flex;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  background: rgb(247, 243, 228);
  &__sidebar {
    width: 30%;
    background: url(./assets/images/img/bg/love.jpg);
    background-repeat: repeat-y;
    font-size: large;
    @include tablets {
      background-color: rgba(0, 191, 165, .95);
      height: 100vh;
      width: 0px;
      position: fixed;
      left: 0;
      top: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      z-index: 5;
      overflow: hidden;
      transition: .4s ease-in-out;
      &--active {
        width: 56%;
        @include phones-big {
          width: 70%;
        }
        @include phones-small {
          width: 82%;
        }
      }
    }
  }
  &__articles {
    width: 60%;
    @include tablets {
      width: 100%;
    }
    @include phones-big {
      width: 100%;
    }
  }
  @include tablets {
    margin: 0 auto;
    padding: 30px 15px;
  }
}

.fanart{
  background: rgb(25, 33, 58);
}

.sidebar-mobile__btn {
  width: 0px;
  height: 70px;
  background-color: rgba(0, 191, 165, .9);
  border-radius: 50%;
  top: 50%;
  left: -55px;
  display: none;
  transform: translateY(-50%);
  position: fixed;
  transition: .4s ease-in-out;
  @include tablets {
    display: block;
  }
}

.sidebar-mobile__btn--active {
  left: 33%;
  @include phones-big {
    left: 59%;
  }
  @include phones-small {
    left: 70%;
  }
}

.sidebar {
  padding-top: 10px;
}

.sidebar__list {
  text-align: right;
  &--active {
    position: fixed;
    top: 5%;
    left: 0;
    transform: translateY(-5%);
    max-width: 30%;
  }
  @include tablets {
    position: relative;
    left: 0;
    top: 50%;
    text-align: left;
    transform: translateY(-50%);
    max-width: 260px;
    margin: 0 auto;
  }
  @include phones {
    width: 250px;
  }
}

.sidebar__item {
  padding: 5px;
  transition: 0.3s;
  margin-bottom: 5px;
  @include tablets {
    padding: 5px;
    font-size: 15px;
    font-weight: 500;
    transition: 0.3s;
    margin-bottom: 5px;
    &--active {
      color: #fff;
      border-right: 3px solid #fff;
    }
  }
  @include phones {
  }
  @include phones-small {
    padding: 8px;
  }
}

.sidebar__item--active {
  color: #02fa72;
  border-right: 3px solid #02fa72;
  @include tablets {
    border-right: 3px solid #fff;
  }
}

.sidebar__link {
  display: block;
  width: 100%;
  height: 100%;
  font-family:'Lutsk-sity'; 
  font-size: 30px;
  text-align: center; 
  background-color: rgb(2, 11, 39);
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #f78606;
  &:hover {
    color: rgb(118, 41, 243);
    border: dotted rgba(0, 191, 165, .9);
    background: rgba(255, 245, 245, 0.699);
  }
  @include tablets {
    opacity: 0.8;
    font-size: 24px;
  }
}

.sidebar__item__info{
  font-family: 'Intro Inline';
  background-color: rgba(241, 139, 70, 0.925);
  border-radius: 8px;
  color: rgb(11, 87, 43);
  font-size: 15px;
  text-align: center;
}

.articles {
  padding-bottom: 187px;
  @include tablets {
    width: 100%;
  }
}

.articles__item {
  margin-top: 20px;
  margin-bottom: 20px;
  border-bottom: 2px solid rgba(128, 128, 128, 0.509);
  width: 90%;
  &:last-child {
    border: none;
  }
  @include tablets {
    width: 100%;
    margin: 0 auto;
    font-size: 14px;
  }
}

.articles__title {
  font-family: 'BernierRegular';
  font-size: 35px; 
  @include phones-small {
    font-size: 21px;
  }
}

.articles__date {
  color: #c048f8;
  font-weight: 420;
  margin: 10px 0;
}

.articles__text {
  font-size: 16px;
  margin-bottom: 20px;
}

.desc-blog {
  top: 64%;
}

.table {
  width: 100%;
  height: 100%;
  border-collapse: collapse;
  font-family: monospace;
  margin-bottom: 20px;
  &-row {
    background-color: #f5f2f0;
  }
}

.table-1cell-width {
  width: 15px;
  height: 23px;
  background-color: #e7e5e3;
  text-align: right;
  padding-right: 10px;
}

.table-2cell-width {
  width: 200px;
}

b {
  color: black;
  font-weight: 700;
}

i {
  color: #84ac32;
  font-style: normal;
}

.news{
background-color: rgb(28, 168, 133);
display: inline-block;
font-family:'Lutsk-sity'; 
font-size:22px;
color: rgb(141, 7, 112);
text-align:  center;
height: 80px;
-webkit-text-stroke-width: 1px;
-webkit-text-stroke-color: #dd7804;
@include tablets {
  font-size: 18px;
  width: 170px;
}
}

.photo_text{
  text-align: center;
  color: rgb(175, 6, 161);
  font-size: 16px;
  margin-bottom: 15px;
}

.picture__size{
width: 500px;
height: 500px;
@include tablets {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
}

.art__size{
width: 340px;
height: 340px;
}

.photo__size{
  width: 600px;
  height: 300px;
  }


  .avatar__size{
    width: 400px;
    height: 550px;
    @include tablets {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    }

  .illustration__size{
    width: 500px;
  height: 400px;
  @include tablets {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
  }

  .background{
    background-image: url(assets/images/img/bg/Dream5.jpg);
  }

.video{
  @include tablets {
    width: 100%;
    height: 300px;
    margin: 0 auto;
    display: block;
    max-width: 500px;
  }
  @include phones-big {
    width: 100%;
    height: 200px;
    margin: 0 auto;
    display: block;
    max-width: 500px;
  }

}