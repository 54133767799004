.fanart__picture {
    width: 236px;
    height: 380px;
    object-fit: cover;
    border: 3px inset #f76301;
    box-shadow: -6px 6px 5px 0px #f58aff;
}

.fanart__title {
    font-family: 'BernierRegular';
    font-size: 33px;
    background-color:#fafafa;
    text-align: center;
    border-radius: 20px;
    @include phones-small {
      font-size: 21px;
    }
  }

  .bright{
      color: rgb(223, 190, 3);
      font-family: "New_Zelek";
      font-size: 37px;
      text-align: center;
  }

  .autor{
      text-align: center;
      border-radius: 8px;
      background-color: rgb(247, 243, 228);
  }

  .fanf-title{
      text-align: center;
      color: rgb(7, 47, 223);
      background-color: rgb(172, 151, 34);
      border-radius: 8px;
  }