.hero__section {
  width: 100%;
  height: 100vh;
  min-height: 450px;
  background-image: url('assets/images/img/bg/sky.jpg');
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-flow: wrap;
  align-items: flex-start;
  justify-content: center;
  @include tablets {
    min-height: 400px;
  }
  @include phones-small {
    min-height: 400px;
  }
}

.hero__title {
  @include absolute;
  height: 50%;
  width: 90%;
  @include phones-small {
    top: 45%;
  }
}

.hero__title-pic {
  position: absolute;
  fill: #fff;
  height: 100%;
  width: 100%;
  opacity: 0.08;
  @include phones-big {
    opacity: 0;
  }
}

.hero__user {
  max-width: 380px;
  margin: 0 auto;
  z-index: 1;
  @include phones-small {
    position: absolute;
    top: 35%;
  }
}

.hero__avatar {
  width: 143px;
  height: 143px;
  margin: 0 auto;
}

.hero__avatar-img {
  width: 160px;
  height: 160px;
  border-radius: 50%;
}

.hero__description {
  color: #fff;
  font-family: 'Connie';
  text-shadow: -5px -5px rgb(2, 85, 50);
  text-align: center;
  font-size:18px;
  @include phones-big {
    font-size: 20px;
    font-weight: 300;
  }
}

.hero__name {
  font-size: 58px;
  font-weight: 600;
  font-family: 'DS Kork';
  text-shadow: -5px -5px rgb(2, 85, 50);
  @include phones-big {
    font-size: 50px;
    font-weight: 400;
  }
  @include phones-small {
    font-size: 47px;
    font-weight: 400;
  }
}

.hero__down-arrow {
  position: absolute;
  bottom: 10%;
  left: 50%;
  transform: translateX(-50%);
}

.down-arrow {
  opacity: 0.6;
  transition: opacity 0.2s;
  fill: #fff;
  transition-duration: 1s;
  &:hover {
    opacity: 1;
  }
}

.down-arrow__img {
  width: 26px;
  height: 36px;
  transition: 0.2s;
  &:hover {
    width: 28px;
    height: 28px;
  }
}

