*,
*::before,
*::after {
  box-sizing: border-box; // border: 1px solid #ff0000;
}

body,
html {
  height: 100%;
  padding: 0;
  margin: 0;
}

body {
  font-family: 'Roboto', Helvetica, sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.42;
  color: black;
  cursor: url(./assets/images/Arrow.png), default;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  margin: 0;
}

button {
  border: 0;
  cursor: url(./assets/images/Pointer.png), default;
  outline: none;
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

a {
  text-decoration: none;
  color: rgb(2, 11, 39);
  font-weight:bolder;
  cursor: url(./assets/images/Pointer.png), default;
}

p {
  margin: 0;
  padding: 0;
}
small {
  font-size: 15px;
  @include phones-big {
    font-size: 13px;
  }
}
blockquote {
  margin: 0;
}
address {
  font-style: normal;
}

// instead of display: none;
.visuallyhidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

.wrapper {
  overflow: hidden;
  min-height: 100%;
  position: relative;
}