// Preloader
.preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background-color: rgb(125, 218, 204);
  &__spinner {
    width: 150px;
    height: 150px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &__percent {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
  }
  &__animation {
    width: 150px;
    height: 150px;
    will-change: transform;
    animation: spin 2s linear infinite;
    &,
    &::before,
    &::after {
      content: '';
      display: block;
      position: absolute;
      border: 5px solid transparent;
      border-top: 5px solid rgba(253, 118, 231, 0.842);
      border-radius: 50%;
    }
    &::before {
      $pos: 7px;
      top: $pos;
      left: $pos;
      right: $pos;
      bottom: $pos;
      animation: spin 4s linear infinite;
    }
    &::after {
      $pos: 19px;
      top: $pos;
      left: $pos;
      right: $pos;
      bottom: $pos;
      animation: spin 3s linear infinite;
    }
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

// Parralax
.hero__parralax {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  max-height: 1270px;
}

.hero__parralax-img {
  display: block;
  width: 100%;
  height: 100%;
  max-width: 180%;
  position: absolute;
  bottom: 0;
  left: 0;
  -webkit-clip-path: polygon(0% 0%, 100% 0, 100% 90%, 50% 100%, 0 90%);
  clip-path: polygon(0% 0%, 100% 0, 100% 85%, 50% 100%, 0 85%);
  @include tablets {
    -webkit-clip-path: polygon(0% 0%, 100% 0, 100% 90%, 50% 100%, 0 90%);
    clip-path: polygon(0% 0%, 100% 0, 100% 92%, 50% 100%, 0 92%);
  }
  @include phones-big {
    -webkit-clip-path: polygon(0% 0%, 100% 0, 100% 90%, 50% 100%, 0 90%);
    clip-path: polygon(0% 0%, 100% 0, 100% 95%, 50% 100%, 0 95%);
  }
}

// Header
.hero__header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 15px 2%;
  width: 100%;
  @include phones-big {
    padding: 10px 1%;
  }
}

// block__socials
.socials__list {
  display: flex;
  justify-content: flex-end;
  text-align: center;
}

.socials__item {
  width: 40px;
  height: 40px;
  margin-right: 15px;
  z-index: 1;
  transition: all .4s ease;
  @include phones-big {
    margin-right: 10px;
  }
}

.socials__link {
  display: block;
  width: 100%;
  height: 100%;
}

.socials__link-icon {
  width: 30px;
  height: 30px;
  fill-opacity: 0.6;
  fill: #fff;
  transition-duration: 0.3s;
  &:hover {
    fill-opacity: 1;
    width: 28px;
    height: 39px;
  }
  @include phones-big {
    width: 23px;
    height: 23px;
  }
}

.socials__link-icon--footer {
  background-color: #ffffff87;
  border: 1px solid #ffffff87;
  fill: rgba(57, 5, 243, 0.85);
  height: 35px;
  transition: .3s;
  width: 35px;
  &:hover {
    background-color: rgba(164, 2, 170, 0.85);
    border: 1px solid rgba(164, 2, 170, 0.85);
    fill: #fff;
  }
}

// block menu
.hero__nav {
  position: absolute;
  display: flex;
  z-index: 0;
  align-items: center;
  justify-content: center;
  right: 0;
  left: 0;
  top: 0;
  height: 100vh;
  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background-color: rgba(0, 191, 165, .9);
    width: 50%;
    transform: translateX(-100%);
    transition: .4s;
  }
  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    background-color: rgba(0, 191, 165, .9);
    width: 50%;
    transform: translateX(100%);
    transition: .4s;
  }
  &--active {
    z-index: 5;
    &::before,
    &::after {
      transform: translateX(0);
    }
    .hero__list {
      opacity: 1;
      transform: translateY(0);
    }
  }
}

.hero__list {
  list-style: none;
  padding: 0;
  z-index: 10;
  transition: all .2s ease .2s;
  transform: translateY(20px);
  opacity: 0;
  text-align: center;
  
}

.hero__link {
  color: #fff;
  font-family: "DS Note";
  font-size: 35px;
  font-weight: 400;
  transition: all 0.3s;
  &:hover {
    &::before {
      display: inline-block;
      content: '-';
      font-size: 35px;
      opacity: .6;
      margin-right: 15px;
    }
    &::after {
      display: inline-block;
      content: '-';
      font-size: 35px;
      opacity: .6;
      margin-left: 15px;
    }
  }
  @include phones-big {
    &::before {
      display: inline-block;
      content: '-';
      font-size: 35px;
      opacity: .3;
      margin-right: 15px;
    }
    &::after {
      display: inline-block;
      content: '-';
      font-size: 35px;
      opacity: .3;
      margin-left: 15px;
    }
  }
  @include phones-small {
    font-size: 28px;
  }
}

.hero__background {
background-image: url('assets/images/img/bg/music_paper2.png'); 
z-index: 20;
}

.hamburger {
  z-index: 10;
}

// menu humburger
// base
$hamburger-layer-color: #fff !default;
$hamburger-layer-border-radius: 4px !default;
$hamburger-hover-opacity: 0.7 !default;
$hamburger-hover-transition-duration: 0.15s !default;
$hamburger-hover-transition-timing-function: linear !default;
$hamburger-hover-use-filter: false !default;
$hamburger-hover-filter: opacity(50%) !default;
$hamburger-types: (spring) !default;
.hamburger {
  padding: 15px;
  display: inline-block;
  cursor: url(./assets/images/Pointer.png), default;
  transition-property: opacity, filter;
  transition-duration: $hamburger-hover-transition-duration;
  transition-timing-function: $hamburger-hover-transition-timing-function;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
  &:hover {
    @if $hamburger-hover-use-filter==true {
      filter: $hamburger-hover-filter;
    }
    @else {
      opacity: $hamburger-hover-opacity;
    }
  }
}

.hamburger-box {
  width: 40px;
  height: 24px;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -3px;
  &,
  &::before,
  &::after {
    width: 30px;
    height: 4px;
    background-color: $hamburger-layer-color;
    border-radius: $hamburger-layer-border-radius;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
  }
  &::before,
  &::after {
    content: "";
    display: block;
  }
  &::before {
    top: 0px;
  }
  &::after {
    bottom: 0px;
  }
}

@if index($hamburger-types, spring) {
  .hamburger--spring {
    .hamburger-inner {
      top: 2px;
      transition: background-color 0s 0.13s linear;
      &::before {
        top: -10px;
        transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
      }
      &::after {
        bottom: -10px;
        transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
      }
    }
    &.is-active {
      .hamburger-inner {
        transition-delay: 0.22s;
        background-color: transparent;
        &::before {
          top: 0;
          transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
          transform: translate3d(0, 10px, 0) rotate(45deg);
        }
        &::after {
          top: 0;
          transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
          transform: translate3d(0, 10px, 0) rotate(-45deg);
        }
      }
    }
  }
}

input:required:valid {
  border-color: green;
  color: green;
}

.invalid {
  border-color: red;
  color: red;
}

.error-message {
  animation-name: error;
  animation-duration: 1s;
  width: 320px;
  border-radius: 10px;
  margin-bottom: 10px;
  background-color: lightgoldenrodyellow;
  padding: 5px;
  text-align: center;
  white-space: pre-wrap;
  color: crimson;
  font-weight: 600;
  display: none;
  &--active {
    display: block;
  }
}

@keyframes error {
  0% {
    transform: translate(-5px);
  }
  25% {
    transform: translate(5px);
  }
  50% {
    transform: translate(-5px);
  }
  75% {
    transform: translate(5px);
  }
  100% {
    transform: translate(0);
  }
}