// Bernier Font
@font-face {
  font-family: 'BernierDistressed';
  src: url('assets/fonts/Bernier/BERNIERDistressed-Regular.woff') format('woff'), url('/assets/fonts/bernier/BERNIERDistressed-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'BernierRegular';
  src: url('assets/fonts/Bernier/BERNIERRegular-regular-webfont.woff') format('woff'), url('/assets/fonts/bernier/BERNIERRegular-regular-webfont.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'BernierShade';
  src: url('assets/fonts/Bernier/BERNIERShade-Regular.woff') format('woff'), url('/assets/fonts/Bernier/BERNIERShade-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

// Awesome Font
@font-face {
  font-family: 'FontAwsome';
  src: url('assets/fonts/FontAwsome/fontawesome-webfont.woff') format('woff'), url('/assets/fonts/FontAwsome/fontawesome-webfont.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

// Roboto Font
@font-face {
  font-family: 'Roboto';
  src: url('assets/fonts/Roboto/Roboto-Light.woff') format('woff'), url('/assets/fonts/Roboto/Roboto-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Roboto';
  src: url('assets/fonts/Roboto/Roboto-LightItalic.woff') format('woff'), url('/assets/fonts/Roboto/Roboto-LightItalic.woff2') format('woff2');
  font-weight: 300;
  font-style: italic;
  font-display: block;
}

@font-face {
  font-family: 'Roboto';
  src: url('assets/fonts/Roboto/Roboto-Regular.woff') format('woff'), url('/assets/fonts/Roboto/Roboto-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Roboto';
  src: url('assets/fonts/Roboto/Roboto-Medium.woff') format('woff'), url('/assets/fonts/Roboto/Roboto-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Roboto';
  src: url('assets/fonts/Roboto/Roboto-Bold.woff') format('woff'), url('/assets/fonts/Roboto/Roboto-Bold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Roboto';
  src: url('assets/fonts/Roboto/Roboto-BoldItalic.woff') format('woff'), url('/assets/fonts/Roboto/Roboto-BoldItalic.woff2') format('woff2');
  font-weight: 600;
  font-style: italic;
  font-display: block;
}


//Lutsk-sity
@font-face {
  font-family: 'Lutsk-sity Regular';
  src: url('assets/fonts/Lutsk-sity/lutsksity.woff') format('woff'), url('/assets/fonts/Lutsk-sity/lutsksity.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
  font-display: block;
}

//a_Concepto
@font-face {
  font-family: 'a_Concepto';
  src: url('assets/fonts/a_Concepto/a-concepto.woff') format('woff'), url('/assets/fonts/a_Concepto/a-concepto.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
  font-display: block;
}

//AA Neon
@font-face {
  font-family: 'AA Neon';
  src: url('assets/fonts/Neon/aa-neon.woff') format('woff'), url('assets/fonts/Neon/aa-neon.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
  font-display: block;
}

//gorillaz regular
@font-face {
  font-family: 'gorillaz1regular';
  src: url('assets/fonts/Gorillaz/gorillaz_1-webfont.woff2') format('woff2'),
       url('assets/fonts/Gorillaz/gorillaz_1-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

//a_bosanovabwbold
@font-face {
  font-family: 'a_bosanovabwbold';
  src: url('assets/fonts/Bosanovabwbold/abosanovabw_bold-webfont.woff2') format('woff2'),
url('assets/fonts/Bosanovabwbold/abosanovabw_bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: 'Intro Inline';
  src: url('assets/fonts/Intro-Inline/intro.otf') format('otf');
  font-weight: normal;
  font-style: normal;

}


@font-face {
  font-family: 'Connie';
  src: url('assets/fonts/Connie/connie.ttf') format('ttf');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}


@font-face {
  font-family: 'New_Zelek';
  src: url('assets/fonts/New_Zelek/New_Zelek.ttf') format('ttf');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'Morning Glory and Cyrillic';
  src: url('assets/fonts/Morning/morning-webfont.woff2') format('woff2'),
       url('/assets/fonts/Morning/morning-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'DS Kork';
  src: url('assets/fonts/Kork/kork.ttf') format('ttf');
  font-weight: 300;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'DS Note';
  src: url('assets/fonts/Note/note.ttf') format('ttf');
  font-weight: 300;
  font-style: normal;
  font-display: block;
}

