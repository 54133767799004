.auth__section {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  min-height: 650px;
  color: #455a64;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: space-around;
  @include tablets {
    background-image: url('assets/images/img/bg/citynight.jpg');
    -webkit-background-size: cover;
    background-size: cover;
    min-height: 100vh;
    justify-content: space-between;
    padding: 30px 0;
  }
  @include phones {
    padding: 10px 0;
    background-image: url('assets/images/img/bg/citynight.jpg');

  }
}

.auth__parralax {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
  max-height: 1270px;
  max-width: 160%;
  overflow: hidden;
  @include tablets {
    display: none;
  }
}

.auth__parralax-layer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.auth__parralax-img {
  position: absolute;
  top: 0;
  left: 55%;
  transform: translateX(-55%);
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
  @include hd {
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    min-height: 1270px;
    min-width: 127%;
  }
}

.auth__btn {
  align-self: flex-end;
  border-radius: 6px;
  border: 1px solid white;
  min-width: 180px;
  max-width: 180px;
  height: 45px;
  background-color: transparent;
  color: white;
  transition: 0.3s;
  margin: 0 3%;
  font-family: "DS Note";
  &:hover {
    background-color:#65dafd;
    color: #bf0050;
  }
  @include phones-big {
    align-self: center;
    margin: 0;
    margin-top: 10px;
  }
}

// Flipper card
.flipper {
  width: 320px;
  max-width: 320px;
  height: 400px;
  perspective: 1000px;
  position: relative;
  @include phones-big {
    height: 370px;
  }
}

// Flipper__front-side
.flipper__user-card {
  background-image: url('assets/images/img/bg/subGang.png');
  backface-visibility: hidden;
  transition: 1s;
  position: absolute;
  padding-top: 15px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  text-align: center;
  justify-content: center;
  align-content: space-between;
  width: 350px;
  height: 100%;
  border-radius: 8px;
  @include phones {
    width: 100%;
    height: 100%;
    object-fit: cover;
    }

}

.flipper__avatar {
  width: 160px;
  height: 160px;
  border-radius: 50%;
}

.flipper__avatar-img {
  max-width: 140px;
  max-height: 140px;
  border-radius: 50%;
}

.flipper__desc {
  padding: 0 5px;
}

.flipper__name {
  font-size: 80px;
  font-weight: 300;
  font-family: "gorillaz1regular";
  color: #06e4c6;
}

.flipper__info{
  font-family: "DavidaC";
  font-weight: 500;

}

.flipper__socials-item {
  padding: 20px;
  margin-top: 50px;
}

.flipper__socials-icon {
  fill: #03e9ca;
  width: 28px;
  height: 28px;
  transition: 0.3s;
  &:hover {
    fill:  rgb(255, 8, 111);
    opacity: 1;
  }
}

.flipper__buttons {
  width: 100%;
}

.flipper__list {
  display: flex;
  height: 45px;
  background: linear-gradient(to left, #017061, #06e4c6);
  border-radius: 8px 8px 8px 8px;
  width: 280px;
  margin: auto;
}

.flipper__item {
  width: 100%;
  height: 100%;
  font-weight: 500;
  border-right: 1px solid #fff;
  transition: 0.3s;
  &:first-child {
    border-radius: 8px 8px 8px 8px;
  }
  &:hover {
    background-color: rgb(243, 24, 97);
    box-shadow: 0 0 10px 0 rgb(243, 24, 97), 0 0 6px 0 rgb(243, 24, 97), 0 0 10px 0 rgb(243, 24, 97);
  }
  &:last-child {
    border-radius: 8px 8px 8px 8px;
    border-right: none;
  }
}

.flipper__link {
  width: 100%;
  height: 100%;
  line-height: 3;
  display: block;
  color: rgb(243, 24, 97);
  font-family: "New_Zelek"; 
  &:hover {
    color: #06e4c6;
  }
}

// Flipper__backside-side
.flipper__authorization {
  background-image: url('assets/images/img/bg/Mudsy.png');
  backface-visibility: hidden;
  transition: 1s;
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 8px;
  display: flex;
  padding-top: 25px;
  justify-content: center;
  align-items: center;
  align-content: space-between;
  flex-wrap: wrap;
  transform: rotateY(180deg);
}

.flipper__title {
  display: flex;
  justify-content: center;
  text-transform: uppercase;
  font-family: 'BernierRegular', sans-serif;
  font-size: 35px;
  width: 100%;
  margin-bottom: 10px;
  &::before {
    display: block;
    content: '-';
    font-size: 35px;
    opacity: .6;
    margin-right: 10px;
  }
  &::after {
    display: block;
    content: '-';
    font-size: 35px;
    opacity: .6;
    margin-left: 10px;
  }
  @include phones {
    font-size: 21px;
  }
}

.flipper__form {
  width: 80%;
  margin: 0 auto;
  text-align: center;
}

.flipper__icon-cont {
  padding: 10px;
  width: 40px;
  height: 40px;
  background-color: #f0efe9;
  border-radius: 5px 0 0 5px;
}

.flipper__icon-login {
  fill: #c4cbcd;
  width: 20px;
  height: 20px;
}

.flipper__inputs {
  display: flex;
}

.flipper__input {
  width: 100%;
  height: 40px;
  border-radius: 0 5px 5px 0;
  padding: 10px 15px;
  outline: none;
  margin-bottom: 20px;
  border: 1px solid transparent;
}

.flipper__checkbox {
  display: none;
}

.flipper__checkbox-fake {
  background-color: #00bea4;
  border: 1px solid grey;
  border-radius: 3px;
  display: inline-block;
  height: 20px;
  width: 20px;
  position: relative;
  vertical-align: bottom;
  margin-right: 10px;
  &::before {
    display: inline-block;
    content: "√";
    transform: scale(0);
    transition: .3s ease;
    color: #fff;
  }
}

.flipper__checkbox:checked+.flipper__checkbox-fake::before {
  transform: scale(1);
}

.flipper__checkbox-text {
  font-size: 14px;
}

.flipper__radio {
  margin-top: 20px;
}

.flipper__radio-btns {
  display: flex;
  justify-content: space-around;
  margin-top: 5px;
}

.flipper__radio-input {
  display: none;
}

.flipper__radio-fake {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  background-color: #fff;
  border: 1px solid gray;
  border-radius: 50%;
  margin-right: 9px;
  vertical-align: bottom;
  &::before {
    background-color: #00bea4;
    border-radius: 50%;
    content: "";
    height: 8px;
    left: 5px;
    position: absolute;
    top: 5px;
    width: 8px;
    transform: scale(0);
    transition: .3s ease-out;
  }
}

.flipper__radio-input:checked+.flipper__radio-fake::before {
  transform: scale(1);
}

.flipper__buttons {
  width: 380px;
  max-width: 380px;
  height: 50px;
  display: flex;
  @include phones {
    width: 300px;
    max-width: 300px;
  }
}

.flipper__button {
  height: 50px;
  width: 50%;
  background: #00bea4;
  border-right: 2px solid #fff;
  color: rgb(76, 2, 247);
  transition: 0.3s;
  font-weight: 600;
  font-family: "New_Zelek"; 
  text-align: center;
  &:last-child {
    border-right: none;
    border-radius: 0 0 5px 0;
  }
  &:first-child {
    border-radius: 0 0 0 5px;
  }
  &:hover {
    background: #027263c9;
  }
}

.flipper-other__side{
  margin-top: 220px;
  
}

.other__side{
 background: linear-gradient(to left, #037546, #028a75);
 text-align: center;
}

.back__text{
color: rgb(204, 175, 12);
}


.copyright-welcome {
  color: #fff;
  @include phones-big {
    text-align: center;
  }
}

