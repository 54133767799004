.comic{
    width: 200px;
    height: 300px;
    border: solid 3px;
}

.language{
    text-align: center;
    background-color: white;
    border-radius: 15px;
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 18px;
    color: rgb(196, 71, 212);
}

.long_comic{
    width: 700px;
    height: 400px;
}

.code_comic{
    width: 500px;
    height: 400px;
}

.photo_comic{
    width: 250px;
    height: 400px;
}