.fanfic__title{
    font-size: 40px;
    color: rgba(3, 110, 96, 0.95);
}

.autor__name{
    font-size: 18px;
    color: #85057e;
}

.main_title{
    font-family: "Lutsk-sity";
    font-size: 45px;
text-align: center;
margin-bottom: 15px;
background-color: rgb(231, 198, 87);
color: rgb(180, 37, 116);
border-radius: 20px;
}

.theory{
    float:left;
}

.journal_red{
    font-weight: bold;
    color: rgb(150, 5, 5);
}

.lyrics{
    font-style: italic;
}