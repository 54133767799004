.footer {
  background-color: rgba(245, 103, 9, 0.925);
  width: 100%;
  height: 125px;
  margin-top: -125px;
  position: absolute;
  color: #fff;
  padding: 15px 15px 0 15px;
  font-weight: 300;
  font-family: 'Intro Inline';
  @include tablets {
    height: auto;
    margin-top: -207px;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  @include phones-big {
    font-size: 13px;
  }
}

.footer__nav {
  width: 100%;
  @include tablets {
    width: 100%;
    margin-bottom: 15px;
  }
}

.footer__list {
  color: #fff;
}

.footer__item {
  display: inline-block;
  margin-right: 17px;
  &:last-child {
    margin-right: 0;
  }
}

.footer__link {
  text-decoration: none;
  opacity: 0.6;
  color: #fff;
  font-weight: 500;
  transition: 0.3s;
  &:hover {
    opacity: 1;
  }
}

.footer__text {
  font-weight: 300;
  max-width: 50%;
  font-style: italic;
  @include tablets {
    max-width: 95%;
    font-size: 15px;
  }
}

.footer__row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #ffffff47;
  &:last-child {
    border-bottom: none;
  }
  @include tablets {
    flex-direction: column;
    flex-wrap: nowrap;
  }
}

