.left__color-side {
  display: inline-block;
  height: 100vh;

  position: absolute;
}

.right__color-side {
  display: inline-block;
  height: 100vh;
  width: 50%;
  left: 50%;
  position: absolute;
  @include tablets {

  }
}

.about {
  width: 100%;
  background-image: url('assets/images/img/bg/Dream5.jpg');
  background-repeat: repeat-y;
  &__description {
    position: relative;
    flex-direction: row;
    @include tablets {
      width: 100%; 
    }
  }
  &__experience {
    width: 700px;
    padding: 15px;
    height: 1450px;
    @include tablets {
      width: 100%;
    }
  }
  @include tablets {
    flex-direction: column;
  }
}

.description {
  text-align: center;
  margin: 0;
  width: 100%;
}

.description__container {
  width: 50%;
  margin: 0 auto;
  padding-bottom: 20px;
  display: inline-block;
  @include hd {
    min-width: 50%;
    justify-content: space-between;
    vertical-align: top;
  }
  @include tablets {
    width: 90%;
    margin: 0 auto;
    vertical-align: top;
  }
  @include phones-small {
    width: 100%;
    max-width: 100%;

  }
}

.about_header-title {
  position: absolute;
  top: -40px;
  left: 0;
  opacity: 0.08;
  width: 100%;
  @include hd {
    width: 50%;
  }
}

.description__title {
  font-size: 63px;
  font-family: 'BernierRegular', sans-serif;
  &::before {
    display: inline-block;
    content: '-';
    font-size: 63px;
    opacity: .6;
    margin-right: 5px;
    @include phones-small {
      font-size: 35px;
    }
  }
  &::after {
    display: inline-block;
    content: '-';
    font-size: 63px;
    opacity: .6;
    margin-left: 5px;
    @include phones-small {
      font-size: 35px;
    }
  }
  @include hd {
    width: 50%;
  }
  @include phones-small {
    font-size: 35px;
  }
}

.description__info {
  width: 100%;
  border-radius: 8px;
  @include hd {
    width: 50%;
  }
}

.description__avatar {
  height: 210px;
  width: 199px;
  margin: auto;
  
}

.description__avatar-img {
  width: 100%;
  height: 100%;
  object-fit: cover;

}


.description__subtitle {
  text-transform: uppercase;
  font-family: 'DS Kork';
  font-size: 35px;
  margin-bottom: 1px;
  color:rgb(134, 9, 9);  
  text-shadow: rgb(190, 5, 5) -1px -1px 0, 
  #333 1px 1px 0;
  &::before {
    display: inline-block;
    content: '-';
    font-size: 35px;
    opacity: .6;
    margin-right: 20px;
  }
  &::after {
    display: inline-block;
    content: '-';
    font-size: 35px;
    opacity: .6;
    margin-left: 20px;
  }
}

.description__text {
  text-align: center;
  font-size: large;
  line-height: 2.0;
  margin-top: 0.5em;
    margin-bottom: 1em;
  color: black;
  font-family: 'Connie';
  border: double white 10px;
  box-shadow: inset 0 0 8px rgba(17, 17, 17, 0.767);
  @include phones-small {
    padding: 0 10px;
  }
}

.description__text-list {
  list-style: disc;
  margin-left: 30px;
}

.experience {
  background-color: #eeebdf;
  height: 950px;
}

.experience__container {
  display: inline-block;
  @include tablets {
    max-width: 80%;
    margin: 0 auto;
  }
  @include phones-big {
    width: 100%;
    max-width: 100%;
  }
}

.experience__title {
  display: flex;
  justify-content: center;
  width: 70%;
  margin: 0 auto;
  text-align: center;
  font-size: 35px;
  font-family: "gorillaz1regular";
  &::before {
    display: block;
    content: '-';
    font-size: 35px;
    opacity: .6;
    margin-right: 10px;
  }
  &::after {
    display: block;
    content: '-';
    font-size: 35px;
    opacity: .6;
    margin-left: 10px;
  }
  @include phones-big {
    font-size: 21px;
    width: 80%;
  }
}

.experience__text {
  margin: 25px 0;
}

.experience__content {
  text-align: left;
  display: inline-block; 
  @include phones-big {
    text-align: center;
  }
}

.skills-list {
  display: flex;
  flex-direction: row;
}

.skills {
  display: flex;
  flex-wrap: wrap;
  @include phones-big {
    justify-content: center;
  }
}

.skills-list__item {
  display: flex;
  @include tablets {
    text-align: center;
    margin: 0 auto;
  }
}

.skills-list__title {
  margin-left: 18px;
  font-size: 28px;
  font-weight: 500;
}

.skill-row__list {
  position: relative;
}

.skill-row__item {
  display: flex;
  justify-content: center;
}

.skill {
  position: relative;
  width: 123px;
  height: 123px;
  margin: 18px;
  font-size: 13px;
  @include tablets {
    width: 130px;
    height: 130px;
    text-align: center;
    word-wrap: break-word;
  }
  @include phones-big {
    width: 130px;
    height: 130px;
  }
  @include phones {
    width: 109px;
    height: 109px;
  }
}

.skill__figure {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: rotate(-90deg);
}

.skill__circle {
  fill: transparent;
  stroke: #ccc;
  stroke-width: 19px;
}

.skill__circle_above {
  transition: 1s;
  stroke: $green;
  stroke-dasharray: 257;
  stroke-dashoffset: 257;
}

.skill__title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 77px;
  color: #00bea4;
  font-weight: 500;
}

.socials__item-round {
  display: inline-block;
  border: 1px solid black;
  border-radius: 50%;
}


.contacts {
  width: 380px;
  max-width: 380px;
  height: 290px;
  max-height: 290px;
  border-radius: 7px;
  background-color: #fcfcfce6;
  text-align: center;
  transition: 0.3s;
  @include phones-big {
    padding-bottom: 0;
    width: 100%;
    max-width: 100%;
    height: 260px;
    max-height: 260px;
  }
  &__title {
    font-family: 'BernierRegular', sans-serif;
    font-style: normal;
    transition: 0.3s;
    font-size: 35px;
    cursor: default;
    &:hover {
      color: #00bea4;
    }
  }
  &__item {
    display: flex;
    align-items: center;
    height: 40px;
  }
  &__icon-pic {
    width: 19px;
    height: 19px;
    margin: 0 15px;
    fill: #00bea4;
  }
  &__link {
    &:hover {
      color: #00bea4;
      font-style: normal;
    }
  }
}

.link-aboutme {
  font-weight: 600;
  text-decoration: underline;
  transition: .3s;
  &:hover {
    color: #00bea4;
  }
}

q {
  font-style: italic;
}

.chara__data {
  font-size: 25px;

}

.green {
  color: rgb(5, 138, 5);
  font-family: 'DS Kork';
  text-shadow: rgb(2, 88, 2) -1px -1px 0, 
  #333 1px 1px 0;
}

.violet__background{
  background-color: rgba(238, 158, 238, 0.603); 
  
}

.violet {
  color: rgb(118, 48, 231);
  font-family: 'DS Kork';
  text-shadow: rgb(116, 38, 252) -1px -1px 0, 
  #333 1px 1px 0;
}

.teal__background{
  background-color: rgba(5, 168, 168, 0.815);
}

.choko{
  color: rgb(179, 88, 3);
  font-family: 'DS Kork';
}

.smoke__background{
  background-color: rgba(106, 147, 224, 0.849);
}

.golden__background{
  background-color: rgba(219, 152, 8, 0.753);

}

.blue{
  color: rgb(15, 94, 211);
  font-family: 'DS Kork';
  text-shadow: rgb(3, 41, 165) -1px -1px 0, 
  #333 1px 1px 0;
}

.description__twin{
  width: 100%;
}


