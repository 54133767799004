.round {
    border-radius: 200px; 
    border-style: solid;
    border: 10px double rgb(243, 180, 6); 
    width: 170px;
    height: 170px;
   }



    .text{
    border-radius: 5px; 
    border: 2px dotted rgb(243, 180, 6); 
    }


    .layer1_quote {
        float: left; 
        width: 200px; 
       }
       .layer2 {
        padding: 15px; 
        width: 550px; 
        float: left;
        margin-left: 25px;

       }

       .main{
        display: flex;
        flex-wrap: wrap;
        margin-top: 15px;
       }



       .round_photo {
        border-radius: 200px; 
        border-style: solid;
        border: 10px double rgb(243, 180, 6); 
        width: 130px;
        height: 130px;
       }

       .block1 {
        float: left; 
        width: 180px; 
       }
       .block2 {
        padding: 15px; 
        width: 400px; 
        float: left;
        margin-left: 5px;}

        .no_human {
            margin-bottom: 10px;}

            .monkey_photo{
                border-radius: 200px; 
                border-style: solid;
                border: 10px double #f3b406; 
                width: 250px;
                height: 250px;   
                margin-right: 15px;     
                margin-top: 25px; 
                @include tablets {
                    width: 200px;
                    height: 200px;   
     
                }
            }

            .duo{
                display: flex;
                flex-wrap: wrap;
                margin-bottom: 70px;  
            }

            .title_border {
                border-radius: 200px;  
                border: 5px double #5b79fd;
            }


            .humanz_title{
                color: rgba(3,110,96,.95);
                font-size: 40px;
                font-family: BernierRegular;
            }

.musicians{
    border: 5px ridge #5b79fd;
    border-radius: 5px;
}

.song{
    background-image:  url(imgs/skull.jpg);
    }
    
    .humanz_link{
        border: 2px dotted rgb(4, 102, 48);
       color: rgb(170, 106, 9);
       font-size: large;
    }
   
    

    .right_coner{
        margin-left: 50px;
    }

    .round_char {
        border-radius: 200px; 
        border-style: solid;
        border: 10px double rgb(243, 180, 6); 
        width: 150px;
        height: 150px;
       }

       .text_quote{
        border-radius: 5px; 
        border: 2px dotted rgb(243, 180, 6); 
        }

        .layer1_quote{
            float: left; 
            width: 170px; 
           }
           .layer2_quote {
            padding: 15px; 
            width: 520px; 
            float: left;
            margin-left: 10px;
            line-height: 1.3;
           }

           .main_quote{
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 35px;
           }