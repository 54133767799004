@mixin absolute {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

// responsive
// @function rem($px) {
//   @return $px / 16px+0rem;
// }
//mobile mixin
@mixin hd {
  @media screen and (min-width: 1440px) {
    @content;
  }
}

@mixin desktop {
  @media screen and (max-width: 1200px) {
    @content;
  }
}

@mixin laptop {
  @media screen and (max-width: 992px) {
    @content;
  }
}

@mixin tablets {
  @media screen and (max-width: 768px) {
    @content;
  }
}

@mixin phones-big {
  @media screen and (max-width: 414px) {
    @content;
  }
}

@mixin phones {
  @media screen and (max-width: 375px) {
    @content;
  }
}

@mixin phones-small {
  @media screen and (max-width: 320px) {
    @content;
  }
}