.slider {
  height: 100%;
  min-height: 650px;
  width: 100%;
  display: grid;
  background-image: url('assets/images/img/bg/Pineapple.jpg'); 
  grid-template: "info display" 70% "info buttons" 30% / 1fr 1fr;
  @include tablets {
    grid-template: "display" 400px "info" 300px "buttons" 200px / 1fr;
  }
  @include phones {
    grid-template: "display" 255px "info" 220px "buttons" 95px / 1fr;
    font-size: 16px;
  }
}

.slider__info-container {
  grid-area: info;
  display: flex;
  align-items: center;
  justify-content: center;
}

.slider__display {
  grid-area: display;
  background: #039682;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slider__buttons {
  grid-area: buttons;
  display: flex;
}

.slider__button {
  overflow: hidden;
  cursor: pointer;
  user-select: none;
  transition: .5s;
  width: 50%;
  position: relative;
  &:hover {
    opacity: .5;
  }
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(#000, .6);
    z-index: 10;
  }
  &:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 44px;
    height: 29px;
    background: svg-load("portf_arrow_down.svg", fill="#ffffff") center center no-repeat / contain;
    z-index: 20;
  }
  &--next {
    &:after {
      background: svg-load("portf_arrow_up.svg", fill="#ffffff") center center no-repeat / contain;
    }
  }
}

.slider__big-pic {
  display: block;
  max-width: 90%;
  max-height: 90%;
}

.slider__button-img-wrap {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.slider__button-pic {
  display: block;
  max-width: 90%;
  max-height: 90%;
}

.slider__info {
  text-align: center;
  font-family: 'AA Neon';
  color:rgb(187, 160, 5); 
  &-button {
    margin: 20px auto;
    display: flex;
    justify-content: center;
    font-family: 'Connie';
  }
}

.slider__info-skills {
  border-top: 1px solid #00bfa5;
  border-bottom: 1px solid #00bfa5;
  padding: 15px 0;
  color: #06ceb3;
  text-transform: uppercase;
  text-align: center;
  font-size: 0;
  background-color: rgba(233, 6, 112, 0.856);
  span {
    font-size: 18px;
    font-weight: 800;
    display: inline-block;
    font-family: 'Connie';
    &.whitespace {
      &:after {
        content: "\00a0";
      }
    }
  }
}

.subtitle-works {
  font-size: 35px;
  background-color: rgba(233, 6, 112, 0.856);
  @include phones {
    font-size: 21px;
  }
}

.button {
  border: 1px solid #00bfa5;
  background-color: #00bfa5;
  color: #fff;
  border-radius: 6px;
  height: 45px;
  width: 200px;
  display: flex;
  justify-content: center;
  flex-direction: row-reverse;
  align-items: center;
  transition: .3s ease;
  &:hover {
    background-color: #058372;
  }
}

.link-pic {
  width: 24px;
  height: 20px;
  fill: #fff;
  padding-right: 5px;
  &:hover {
    fill: #00bfa5;
  }
}

.slider__button-img-container {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.slide-down-enter-active,
.slide-down-leave-active {
  transition: 1s;
}

.slide-down-leave {
  bottom: 0%;
}

.slide-down-leave-to {
  bottom: -100%;
}

.slide-down-enter {
  bottom: 100%;
}

.slide-down-enter-to {
  bottom: 0%;
}

.slide-up-enter-active,
.slide-up-leave-active {
  transition: 1s;
}

.slide-up-leave {
  bottom: 0%;
}

.slide-up-leave-to {
  bottom: 100%;
}

.slide-up-enter {
  bottom: -100%;
}

.slide-up-enter-to {
  bottom: 0%;
}

@keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(25px, 0, 0);
  }
  75% {
    transform: translate3d(-10px, 0, 0);
  }
  90% {
    transform: translate3d(5px, 0, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}

.bounceInLeft {
  animation-duration: .4s;
  animation-name: bounceInLeft;
}

@keyframes bounceInDown {
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    transform: translate3d(0, -3000px, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(0, 25px, 0);
  }
  75% {
    transform: translate3d(0, -10px, 0);
  }
  90% {
    transform: translate3d(0, 5px, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}

.bounceInDown {
  animation-duration: .4s;
  animation-name: bounceInDown;
}

.font__size{
  font-size: 20px;
}